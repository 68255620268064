/* eslint-disable */
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import equalizerMultiple from './equalizer-multiple';

gsap.registerPlugin(ScrollToPlugin);
const filters = document.querySelectorAll('[data-filter]');

if (filters.length > 0) {
    let historySupport = !!(window.history && history.pushState);
    let ajaxinateInstance;



    function filterListener() {
        console.log('added ev')
        /* Business Unit filters */

        let miniBusiness = document.querySelectorAll('.business-mini-filter')
        if (miniBusiness) {
            miniBusiness.forEach(elem => {
                elem.addEventListener('click', (event) => {
                    event.preventDefault()

                    miniBusiness.forEach(el => {
                        el.classList.remove('active');
                    })

                    elem.classList.add('active');
                    addOrUpdateURLParam('category', elem.dataset.filter, event)
                })
            })
        }

        let selectContainer = document.querySelector('[data-container]');
        let select = document.querySelector('[data-select]');
        let selected = document.querySelector('[data-selected-filters]');
        let options = document.querySelectorAll('.select-container .option button');
        let optionsContainer = document.querySelector('.option-container');
        // let optionsHeight = optionsContainer.clientHeight;

        // Dropdown List Options Height
        function selectClick() {
            selectContainer.classList.toggle('active');
            if (selectContainer.classList.contains('active')) {
                gsap.to(optionsContainer, { height: 'auto', ease: 'none' });
                // optionsContainer.style.height = optionsHeight + 'px';
            } else {
                gsap.to(optionsContainer, { height: 0, ease: 'none' });
            }
        }
        select.onclick = () => {
            selectClick()
        };

        options.forEach((selectedOption) => {
            selectedOption.addEventListener('click', (e) => {
                selected.textContent = 'Filter: ' + selectedOption.innerText;
                //selectContainer.classList.remove('active');
                selectClick();
                options.forEach((selectedOption) => {
                    selectedOption.classList.remove('selected');
                });
                selectedOption.classList.add('selected');

                addOrUpdateURLParam('category', selectedOption.dataset.filter, e)
            });
        });
    }

    function paginationListeners(){
        let paginationAction = document.querySelectorAll('.pagination-action')

        if (paginationAction) {
            paginationAction.forEach(elem => {
                elem.addEventListener('click', (event) => {
                    event.preventDefault()
                    paginationChange(elem.getAttribute('href'));
                })
            })
        }
    }

    filterListener()
    paginationListeners()

    let filterBusiness = document.querySelector('#filterBusinessUnit')
    let dropdownNews = document.querySelector(".filters__wrapper");

    let miniBusiness = document.querySelectorAll('.business-mini-filter')


    let restartFilters = document.querySelectorAll('.restart-filters')
    let paginationAction = document.querySelectorAll('.pagination-action')

    function addOrUpdateURLParam(key, value, event) {
        const searchParams = new URLSearchParams(window.location.search)
        const cleanPages = window.location.pathname.replace(`${searchParams}`, "");
        const paginationIndex = (window.location.pathname.length - window.location.pathname.lastIndexOf('/p')) - 1
        let cleanURL = cleanPages.slice(0, -paginationIndex)
        let ajaxContainer = document.querySelector('#AjaxinateContainer');
        let createdspinner = document.querySelector('.loading-spinner');

        if (value == "all") {
            const newRelativePathQuery = cleanURL + "?" + searchParams.toString()
            searchParams.delete('category')

        } else {
            searchParams.set(key, value)
        }

        const newRelativePathQuery = cleanURL + "?" + searchParams.toString()
        history.pushState(null, "", newRelativePathQuery)

        
        const headers = new Headers()
        headers.set('Content-Type', 'text/html')
        
        ajaxContainer.classList.add('loading-data');
        createdspinner.classList.add('active');
        
        fetch(window.location.href, {
            method: 'GET',
            headers: headers,
        })
        .then((response) => response.text())
        .then((result) => {
            // history.pushState(result, '', );
            refreshProjects(result)
            // updateBox()
        })
        .catch((error) => {
            ajaxContainer.classList.remove('loading-data');
            createdspinner.classList.remove('active');
            console.error(error)
        })
    }

    function refreshProjects(html) {
        let generalContainer = document.querySelector('.results-container')
        let ajaxContainer = document.querySelector('#AjaxinateContainer');
        let createdspinner = document.querySelector('.loading-spinner');

        let parser = new DOMParser();

        // Parse the text
        let newHTML = parser.parseFromString(html, "text/html");
        let newContent
        if (newHTML.querySelector(".no-results")) {
            newContent = newHTML.querySelector(".no-results")
        } else {
            newContent = newHTML.querySelector("#AjaxinateContainer")

        }

        ajaxContainer.innerHTML = "";


        setTimeout(function () {
            createdspinner.classList.remove('active');
            ajaxContainer.classList.remove('loading-data');
            ajaxContainer.append(newContent);

            paginationListeners();
            scroll()
            equalizerMultiple()
        }, 800);


    }


    function paginationChange(url) {
        // const replaceText = url.replace(`${window.location.origin}`, "")
        history.pushState(null, "", url)

        // let cleaneadUrl = window.location.origin + replaceText



        const headers = new Headers()
        headers.set('Content-Type', 'text/html')

        fetch(/*cleaneadUrl*/url, {
            method: 'GET',
            headers: headers,
        })
            .then((response) => response.text())
            .then((result) => {
                refreshProjects(result)
            })
            .catch((error) => {
                console.error(error)
            })

    }

    function scroll() {
        const element = document.getElementById('filter-listing');

        gsap.to(window, {
            delay: 0,
            duration: 0.5,
            ease: 'power2.in',
            scrollTo: { y: element, offsetY: 150 },
        });

    }
}


