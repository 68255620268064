// resident-spotlight-with-card

import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const residentSpotCardAnim = () => {
  const residentsSpot = gsap.utils.toArray(
    'section.resident-spotlight-with-card, section.resident-spotlight'
  );

  if (residentsSpot.length > 0) {
    residentsSpot.forEach((el) => {
      const screen = el.querySelector('.screen');

      const img = el.querySelector('.picture-image.animated img');
      const textContainer = el.querySelector('.rs-text-wrapper');

      gsap.to(screen, {
        x: 0,
        ease: 'power2.out',
        scrollTrigger: {
          trigger: el,
          start: 'top 80%',
        },
      });

      gsap.from(textContainer, {
        alpha: 0,
        delay: 0.3,
        scrollTrigger: {
          trigger: el,
          start: 'top 80%',
        },
      });

      gsap.from(img, {
        alpha: 0,
        delay: 0.8,
        scrollTrigger: {
          trigger: el,
          start: 'top 80%',
        },
      });

      gsap.fromTo(
        img,
        { yPercent: 5 },
        {
          yPercent: -5,
          scrollTrigger: {
            trigger: el,
            start: 'top bottom',
            end: '100% top',
            scrub: true,
            // markers: true,
          },
        }
      );
    });
  }
};

export default residentSpotCardAnim;
