import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default function announcements() {
    const announcementBanners = document.querySelectorAll('[data-announcement-banner]');

    if(announcementBanners.length) {
        const mm = gsap.matchMedia();

        announcementBanners.forEach((banner) => {
            const bannerId = banner.getAttribute('id');
            const bannerButton = banner.querySelector('button');
            const expirationDays = parseInt(banner.dataset.announcementBannerExpirationDays) || 10;

            console.log(getCookie(bannerId))

            if(getCookie(bannerId) === '') {
                gsap.to(banner, {
                    y: 0,
                    delay: 1,
                });
                console.log('running cookie')
            } else {
                console.log(`id="${bannerId}"`)  
            }

            bannerButton.addEventListener('click', () => {
                gsap.to(banner, { yPercent: banner.clientHeight, onComplete: () => {
                    setCookie(bannerId, bannerId, expirationDays);
                    gsap.set(banner, { display: 'none' });
                } });
            });
        });
    }
}

function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}