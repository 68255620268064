import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const pullQuoteAnim = () => {
  const pullQuoteDom = document.querySelectorAll('.pull-quote');
  if (pullQuoteDom) {
    pullQuoteDom.forEach((el) => {
      const imgs = el.querySelectorAll('.picture-image');
      const header = el.querySelectorAll('.pq-text-wrapper');

      gsap.from(imgs, {
        alpha: 0,
        duration: 1,
        ease: 'power4',
        scrollTrigger: {
          trigger: imgs,
          start: 'top 80%',
          // markers: true,
        },
      });

      gsap.from(header, {
        alpha: 0,
        y: 20,
        duration: 1,
        ease: 'power4',
        scrollTrigger: {
          trigger: header,
          start: 'top 80%',
          //   markers: true,
        },
      });
    });
  }
};

export default pullQuoteAnim;
