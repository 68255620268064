import { gsap } from 'gsap';
import { focusHelper, removeFocusHelper } from './utils/focus-helper';

class ModalVideo extends HTMLElement {
  constructor() {
    super();

    this.content = this.querySelector('.content .content-inner');
    this.overlay = this.querySelector('.overlay');
    this.scriptTemplate = this.querySelector('template');
    this.closeButton = this.querySelector('button[data-close-modal]');

    this.openEventHandler = this.open.bind(this);
    this.closeEventHandler = this.close.bind(this);

    this.closeButton.addEventListener('click', this.closeEventHandler);
    document.addEventListener('keydown', this.closeEventHandler);
    this.overlay.addEventListener('click', this.closeEventHandler);
  }

  static get observedAttributes() {
    return ['open'];
  }

  attibuteChangedCallback(name, oldValue, newValue) {
    // console.log(`name: ${name}`);
    // console.log(`oldValue: ${oldValue}`);
    // console.log(`newValue: ${newValue}`);
  }

  connectedCallback() {
    gsap.set(this, { autoAlpha: 0 });
    gsap.set(this.overlay, { autoAlpha: 0 });
    gsap.set(this.content, { scale: 0.8, alpha: 0 });
  }

  open(button) {
    const videoId = button.dataset.id;
    const trigger = button;
    const videoUrl = button.dataset.url;

    if (videoId === null || trigger.getAttribute('aria-expanded') === 'true' || this.hasAttribute('open')) return;

    document.documentElement.classList.add('overflow-hidden');
    this.toggleAttribute('open');
    trigger.setAttribute('aria-expanded', 'true');
    this.setup(videoUrl);
    this.timeline();
  }

  timeline() {
    const timeline = gsap.timeline({ paused: true });
    timeline
      .to(this, { autoAlpha: 1, duration: 0.1 })
      .to(this.overlay, { autoAlpha: 1, duration: 0.5, ease: 'power2.out' }, '>')
      .to(this.content, { scale: 1, alpha: 1, duration: 0.5, ease: 'power2.out', onComplete: () => focusHelper(this.querySelector('.content')) }, '>');

    timeline.play();
  }

  setup(videoUrl) {
    const videoId = this.getId(videoUrl)
    const content = this.scriptTemplate.content.cloneNode(true);
    const iframe = content.querySelector('iframe');
    iframe.src = `${iframe.dataset.src}${videoId}?feature=oembed`;
    iframe.removeAttribute('data-src');
    //content.querySelector('script').dataset.idVideo = videoId;
    this.content.appendChild(content);
  }

  exitTimeline() {
    const timeline = gsap.timeline({ paused: true });
    timeline
      .to(this.content, { scale: 0.8, alpha: 0, duration: 0.5, ease: 'power2.out' })
      .to(this.overlay, { alpha: 0, duration: 0.3, ease: 'power2.out' }, '>')
      .to(this, { autoAlpha: 0, duration: 0.1 }, '>');
    timeline.play();
  }

  removeScript() {
    this.content.firstElementChild.remove();
  }

  close(event) {
    const target = event.target;

    if (!this.hasAttribute('open')) return;

    if (target === this.closeButton || target === this.overlay) {
      this.exitTimeline();
      this.removeScript();
      this.closeTriggers();
      this.removeAttribute('open');
      removeFocusHelper();
      document.documentElement.classList.remove('overflow-hidden');
    }

    if (event.type === 'keydown' && this.hasAttribute('open')) {
      if (event.code.toUpperCase() === 'ESCAPE') {
        this.exitTimeline();
        this.removeScript();
        this.closeTriggers();
        document.documentElement.classList.remove('overflow-hidden');
        this.removeAttribute('open');
        removeFocusHelper();
      }
    }
  }

  closeTriggers() {
    document.querySelectorAll('[data-video-button]').forEach((button) => {
      button.setAttribute('aria-expanded', 'false');
    });
  }

  getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }
}

if (!customElements.get('modal-video')) {
  customElements.define('modal-video', ModalVideo);
}
