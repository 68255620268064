import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const mainHeroAnim = () => {
  const mainHero = document.querySelectorAll('.main-hero');
  if (mainHero) { 
    const mainHeader = document.getElementById('main-header');

    mainHero.forEach((el) => {
      const inLocations = document.querySelector('body').classList.contains('locations');
      const img = el.querySelector('img');
      const imgCont = el.querySelector('.picture-image');
      // const category = el.querySelector('.label-icon-left');
      const header = el.querySelector('.text-wrapper');

      const delay = 0;

      gsap.to(header, {
        alpha: 1,
        y: 0,
        duration: 1,
        ease: 'power4',
        delay,
        scrollTrigger: {
          trigger: el,
          start: 'top 50%',
        },
      });

      gsap.fromTo(
        img,
        { yPercent: 10 },
        {
          yPercent: -10,
          scrollTrigger: {
            trigger: img,
            start: 'top bottom',
            end: '100% top',
            scrub: true,
            // markers: true,
          },
        }
      );

      gsap.to(img, {
        alpha: 1,
        delay,
        scrollTrigger: {
          trigger: el,
          start: 'top 50%',
        },
      });

      gsap.fromTo(imgCont, { yPercent: 40 }, {
        yPercent: 0,
        scrollTrigger: {
          trigger: imgCont,
          start: 'top bottom',
          end: '100% top',
          invalidateOnResize: true,
          scrub: true,
          // markers: true,
        },
      });

      ScrollTrigger.create({
        trigger: el,
        end: 'bottom top',
        onLeave: ()=>{
          mainHeader.classList.add('hide-logo-variation')
        },
        onEnterBack: ()=>{
          mainHeader.classList.remove('hide-logo-variation')
        },
      })

    });
  }
};

export default mainHeroAnim;
