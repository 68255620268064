/* eslint-disable no-plusplus */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable prefer-const */
/* eslint-disable no-inner-declarations */
/* eslint-disable func-names */
/* eslint-disable prefer-template */
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/all';
// import {gsap} from 'gsap';
export default function tabsLocations() {
  const tabsLocation = document.querySelector('#tabsLocation');

  if (tabsLocation) {
    // Tab Change Content

    const locationsNav = document.querySelector('.tabs-header');
    const mainNav = document.getElementById('main-header');
    // const locHeight = locationsNav.parentElement.offsetHeight;

    ScrollTrigger.create({
      trigger: locationsNav,
      start: 'top 0',
      end: () => `${locationsNav.parentElement.offsetHeight}px top`,
      pin: true,
      pinSpacing: false,
      toggleClass: 'condense',
      invalidateOnRefresh: true,
      onEnter: () => {
        mainNav.classList.add('force-hidden');
      },
      onEnterBack: () => {
        mainNav.classList.add('force-hidden');
      },
      onLeave: () => {
        mainNav.classList.remove('force-hidden');
      },
      onLeaveBack: () => {
        mainNav.classList.remove('force-hidden');
      },
      // markers: true,
    });

    window.addEventListener('DOMContentLoaded', () => {
      const tabs = document.querySelectorAll('[role="tab"]');
      const tabList = document.querySelector('.role-tablist');
      // Arrow Keys navigation a11y
      let tabFocus = 0;
      tabList.addEventListener('keydown', (e) => {
        if (e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
          tabs[tabFocus].setAttribute('tabindex', -1);
          if (e.key === 'ArrowRight') {
            tabFocus++;
            if (tabFocus >= tabs.length) {
              tabFocus = 0;
            }
          } else if (e.key === 'ArrowLeft') {
            tabFocus--;
            if (tabFocus < 0) {
              tabFocus = tabs.length - 1;
            }
          }
          tabs[tabFocus].setAttribute('tabindex', 0);
          tabs[tabFocus].focus();
        }
      });
    });
    function onTabClick(buttonTab) {
      // Show Hide Active Content Tabs
      let tabPanelContents = document.getElementsByClassName('tabs-tabpanel');
      for (let i = 0; i < tabPanelContents.length; i++) {
        tabPanelContents[i].classList.remove('active');
      }
      let content = '#' + buttonTab.target.getAttribute('aria-controls');
      let show = document.querySelector(content);
      show.classList.add('active');
      // Toggle Active Button Tab
      let activeButtonClass = '.' + buttonTab.target.classList[3];
      let inactiveButtons = document.querySelectorAll('.button-tab:not(' + activeButtonClass + ')');
      inactiveButtons.forEach(function (item) {
        item.classList.remove('active');
      });
      buttonTab.target.classList.add('active');

      gsap.to(window, { duration: 0.8, scrollTo: '#tabsLocation' });


    }
    const buttonsTab = document.getElementsByClassName('button-tab');
    for (let i = 0; i < buttonsTab.length; i++) {
      buttonsTab[i].addEventListener('click', onTabClick, false);
    }

    // Dropdown Select Tabs Mobile
    let selectContainer = document.querySelector('.select-container');
    let select = document.querySelector('.select');
    let selected = document.getElementById('selectedTabsView');
    let options = document.querySelectorAll('.select-container .option');
    select.onclick = () => {
      selectContainer.classList.toggle('active');
    };
    options.forEach((selectedOption) => {
      selectedOption.addEventListener('click', () => {
        selected.textContent = selectedOption.innerText;
        selectContainer.classList.remove('active');
        options.forEach((selectedOption) => {
          selectedOption.classList.remove('selected');
        });
        selectedOption.classList.add('selected');
      });
    });
  }
}
