import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(ScrollTrigger, SplitText);

const draggerCardsAnim = () => {
  const draggerDom = document.querySelectorAll('.dragger-cards-draggable');
  if (draggerDom) {
    draggerDom.forEach((el) => {
      const cards = el.querySelectorAll('.dragger-card-item');

      gsap.to(cards, {
        alpha: 1,
        duration: 1.3,
        stagger: 0.1,
        scrollTrigger: {
          trigger: el,
          start: 'top 80%',
          // markers: true
        },
      });
    });
  }
};

export default draggerCardsAnim;
