/* eslint-disable */
import { gsap, ScrollTrigger } from "gsap/all";

export default function scrollerBlock() {
    const tabsBlock = document.querySelector('.tabs-block');

    if (tabsBlock) {
        // Tab Change Content
        const tabsHeader = document.querySelector('.tabs-header-container')
        // Buttons Tabs
        const buttonsTab = document.getElementsByClassName('button-tab')
        // panels
        let tabPanelContents = document.getElementsByClassName('tabs-tabpanel');

        window.addEventListener('DOMContentLoaded', () => {
            const tabs = document.querySelectorAll('[role="tab"]')
            const tabList = document.querySelector('.role-tablist')
            // Arrow Keys navigation a11y
            let tabFocus = 0;
            tabList.addEventListener('keydown', (e) => {
                if (e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
                    tabs[tabFocus].setAttribute('tabindex', -1);
                    if (e.key === 'ArrowRight') {
                        tabFocus++;
                        if (tabFocus >= tabs.length) {
                            tabFocus = 0;
                        }
                    } else if (e.key === 'ArrowLeft') {
                        tabFocus--;
                        if (tabFocus < 0) {
                            tabFocus = tabs.length - 1;
                        }
                    }
                    tabs[tabFocus].setAttribute('tabindex', 0);
                    tabs[tabFocus].focus();
                }
            });
        });

        ScrollTrigger.create({
            trigger: tabsHeader,
            start: "top top",
            end: tabsBlock.offsetHeight,
            pin: true,
            onEnter: ()=>{
                tabsHeader.classList.add('pinned');
            }, 
            onLeaveBack: ()=>{
                tabsHeader.classList.remove('pinned');
            },
            pinSpacing: false
        });


        for (let i = 0; i < tabPanelContents.length; i++) {
            ScrollTrigger.create({
                trigger: tabPanelContents[i],
                start: "-=115 top",
                onEnter: ()=>{
                    for (let f = 0; f < buttonsTab.length; f++) {
                        if( buttonsTab[f].getAttribute('aria-controls') == tabPanelContents[i].id ){
                            buttonsTab[f].classList.add('active')
                        }else{
                            buttonsTab[f].classList.remove('active')
                        }
                    }

                },
                onEnterBack: ()=>{
                    for (let f = 0; f < buttonsTab.length; f++) {
                        if( buttonsTab[f].getAttribute('aria-controls') == tabPanelContents[i].id ){
                            buttonsTab[f].classList.add('active')
                        }else{
                            buttonsTab[f].classList.remove('active')
                        }
                    }

                }
            })
        }		


        // On tab item selected
        function onTabSelected(buttonTab) {

            let showContent = document.querySelector('#' + buttonTab.target.getAttribute('aria-controls'))

            gsap.to(window, { duration: 1, scrollTo: {y: showContent, offsetY: 115} })


            // Toggle Active Button Tab
            let activeButtonClass = '.' + buttonTab.target.classList[3];
            let inactiveButtons = document.querySelectorAll('.button-tab:not(' + activeButtonClass + ')')
            inactiveButtons.forEach(function (item) {
                item.classList.remove('active')
            });
            buttonTab.target.classList.add('active')
            // Active Item #id
            var tabActiveId = '#tab-' + buttonTab.target.innerText.toLowerCase().replace(/\s/g, '-')
            //console.log(tabActiveId);
            // add url #id
            var $tabActived = tabActiveId;
            var url;
            if ($tabActived == '') {
                url = '/';
            } else {
                url = `${location.pathname}${$tabActived}`
            }
            window.history.pushState(null, null, url)
            //console.log(url)
            fetch(url)
                .then(response => response.text())
                .catch(function (err) {
                    // There was an error
                    console.warn('Something went wrong.', err)
                });
        }


        for (let i = 0; i < buttonsTab.length; i++) {
            buttonsTab[i].addEventListener('click', onTabSelected, false)
        }

        // Dropdown Select Tabs Mobile
        let selectContainer = document.querySelector('.select-container')
        let select = document.querySelector('.select')
        let selected = document.getElementById('selectedTab')
        let options = document.querySelectorAll('.select-container .option')
        select.onclick = () => {
            selectContainer.classList.toggle('active');
        };
        options.forEach((selectedOption) => {
            selectedOption.addEventListener('click', () => {
                selected.textContent = selectedOption.innerText
                selectContainer.classList.remove('active')
                options.forEach((selectedOption) => {
                    selectedOption.classList.remove('selected')
                });
                selectedOption.classList.add('selected')
            });
        });

        // Tab Selected URL Id
        var currentUrl = window.location.href
        // var currentUrl = 'http://localhost:3000/our-work#test'
        var strsId = currentUrl.split('#')

        if (strsId.length > 1) {
            var currentUrlId = strsId.at(-1)
            let activeButtonTab = document.querySelector('#' + currentUrlId)

            // active button tab desktop
            const buttonsTab = document.getElementsByClassName('button-tab')
            for (let i = 0; i < buttonsTab.length; i++) {
                buttonsTab[i].classList.remove('active')
            }
            activeButtonTab.classList.add('active')
            if (activeButtonTab.classList.contains('active')) {
                // Show Hide Tabpanel content
                let showContent = document.querySelector('#' + activeButtonTab.getAttribute('aria-controls'))
                gsap.to(window, { duration: 1, scrollTo: {y: showContent, offsetY: 115} })
                //console.log(showContent)
            }
            //selected option dropdown mobile
            options.forEach((item) => {
                item.classList.remove('selected')
                console.log(item)
            });
            let optionSelected = document.querySelector('.select-container .option #' + currentUrlId + '-dropdown').parentElement
            optionSelected.classList.add('selected')
            selected.textContent = optionSelected.innerText
        }



    }
}
