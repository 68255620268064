/* eslint-disable no-unused-vars */
import { gsap } from 'gsap';

const customCursor = () => {
  // gsap.registerPlugin(ScrollTrigger)

  const cursorElements = document.querySelectorAll('.custom-cursor');

  cursorElements.forEach((element) => {
    const cursorActives = element.querySelectorAll('.custom-cursor-active');
    const cursor = element.querySelector('.custom-pointer.js');
    const isDragger = cursor.classList.contains('dragger');

    const cursorX = cursor.offsetLeft;
    const cursorY = cursor.offsetTop;
    // const cursorOffsetVal = cursor.offsetHeight / 2;

    cursorActives.forEach(cursorActive => {
      cursorActive.addEventListener('mouseenter', (e) => {
        if (!cursor.classList.contains('leaving')) {
          gsap.set(cursor, { margin: 'unset', left: 0, top: 0, x: cursorX, y: cursorY });
  
  
          gsap.set(cursor, {
            x: e.pageX,
            // yPercent: 0,
            y: e.clientY - cursorActive.getBoundingClientRect().top,
          });
  
          gsap.to(cursor, {
            alpha: 1,
            scale: 1,
            duration: 0.3,
            ease: 'power2.in',
          });
        }
      });


      cursorActive.addEventListener('mousemove', (e) => {
        if (!cursor.classList.contains('leaving')) {
          const rect = e.currentTarget.getBoundingClientRect();
  
          if (e.target.classList.contains('link')) {
            gsap.to(cursor, {
              alpha: 0,
            });
          } else {
            gsap.to(cursor, {
              x: e.pageX,
              y: e.clientY - rect.top,
              // delay: isDragger ? 0 : 0.1,
              delay: 0,
              // duration: isDragger ? 0 : 0.3,
              duration: 0,
            });
            gsap.to(cursor, {
              alpha: 1,
            });
          }
        }
      });
  
      cursorActive.addEventListener('mouseleave', () => {
        cursor.classList.add('leaving');
        if (isDragger) {
          gsap.to(cursor, {
            scale: 0.6,
            duration: 0.3,
            opacity:
              cursor.classList.contains('dragger') || cursor.classList.contains('playing') ? 0 : 1,
            ease: 'power2.in',
            overwrite: true,
            onComplete: () => {
              cursor.classList.remove('leaving');
            },
          });
        } else {
          gsap.to(cursor, {
            scale: 0.6,
            x: cursorX,
            y: cursorY,
            duration: 0.3,
            opacity:
              cursor.classList.contains('dragger') || cursor.classList.contains('playing') ? 0 : 1,
            ease: 'power2.in',
            overwrite: true,
            onComplete: () => {
              cursor.classList.remove('leaving');
            },
          });
        }
      });
  
      cursorActive.addEventListener('mousedown', () => {
        cursor.classList.toggle('playing');
        gsap.to(cursor, {
          scale: 0.9,
        });
      });
  
      cursorActive.addEventListener('mouseup', () => {
        // cursor.classList.toggle('playing');
        gsap.to(cursor, {
          scale: 1,
        });
      });
    })

  });
};

export default customCursor;
