import imgTextAnim from './animations/img-with-text';
import coloredOverflowLogo from './animations/colored-overflow-logo';
import centeredPromoAnim from './animations/centered-promo';
import residentSpotCardAnim from './animations/residentSpotlightWithCard';
import draggerCardsAnim from './animations/draggers-cards';

// delete after nadia QA
// import homepageAnim from './animations/homepage';
// import mainHeroAnim from './animations/main-hero';
// ===

import pullQuoteAnim from './animations/full-quote';
import layerSliderAnim from './animations/layer-slider';
import fullImgCard from './animations/overflow-full-img';
import whiteBlockImg from './animations/white-block-img';
import statsAnim from './animations/stats';
import blurbAnim from './animations/blurb';

const animations = () => {
  // delete after qa
  // homepageAnim();
  // mainHeroAnim();
 // ==
 
  imgTextAnim();
  coloredOverflowLogo();
  centeredPromoAnim();
  residentSpotCardAnim();
  draggerCardsAnim();
  pullQuoteAnim();
  layerSliderAnim();
  fullImgCard();
  whiteBlockImg();
  statsAnim();
  blurbAnim();
  
};

export default animations;
