import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const whiteBlockImg = () => {
  const coloredOverflowDom = document.querySelectorAll('.white-block-with-image');
  if (coloredOverflowDom) {
    coloredOverflowDom.forEach((el) => {

      const img = el.querySelector('.picture-image');
      const textContainer = el.querySelector('.text-wrapper-inner')

    //   gsap.to(screen, {
    //     xPercent: 100,
    //     ease: 'power2.out',
    //     scrollTrigger: {
    //       trigger: el,
    //       start: 'top 80%',
    //     },
    //   });

      gsap.from(textContainer, {
        alpha: 0,
        y: 20,
        delay: 0.3,
        scrollTrigger: {
          trigger: el,
          start: 'top 80%',
        },
      });

    

      gsap.from(img, {
        alpha: 0,
        delay: 0.6,
        scrollTrigger: {
          trigger: el,
          start: 'top 80%',
        },
      });

      gsap.fromTo(
        img,
        { yPercent: 6 },
        {
          yPercent: -6,
          scrollTrigger: {
            trigger: img,
            start: 'top bottom',
            end: '100% top',
            invalidateOnRefresh: true,
            scrub: true,
            // markers: true,
          },
        }
      );

    });
  }
};

export default whiteBlockImg;
