import { gsap } from 'gsap';

export default function layerSlider() {

  const imgs = document.querySelectorAll('.layer-slider-images-gallery-wrapper .image-gallery-individual');
  const images = gsap.utils.toArray(imgs);
  const animDuration = 0.2;

  const buttonsContainers = document.querySelectorAll('.layer-slider-buttons');
  if (images.length) {

    // moving images with clicking them
    // this functionality was removed for now since there are now more than 3 slides per slider
    // images.forEach((img) => {

    //   img.addEventListener('click', () => {

    //     images.forEach((imgs2, t) => {
    //       if (img === imgs2 && t !== 2) {

    //         const imgToMove = images.splice(t, 1)[0];
    //         gsap.to(imgToMove, {
    //           alpha: 0, duration: animDuration, onComplete: () => {
    //             images.push(imgToMove);

    //             images.forEach((imgs3, f) => {
    //               gsap.set(imgs3, { zIndex: f })
    //             })

    //             gsap.to(imgToMove, { alpha: 1, duration: animDuration })
    //           }
    //         })

    //       }
    //     })
    //   })

    //   // gsap.set(img, { zIndex: f })
    // })

    if (buttonsContainers.length) {
      buttonsContainers.forEach((buttonsContainer) => {
        const buttons = buttonsContainer.querySelectorAll('button');
        // let imgMoved;

        buttons.forEach((button) => {
          button.addEventListener('click', () => {

            // decidimos si movemos el slide para adelante o para atras dependiendo del buton q presionamos
            if (button.classList.contains('layer-slider-button-next')) {
              // como presionamos next, sabemos q el slide q hay q mover es siempre el q esta enfrente (q en nuestro array es siempre el slide en el index 2)
              // guardamos ese slide en una variable
              const indexToMove = images.length - 1;
              const imgToMove = images[indexToMove]

              // animamos y despues hacemos lo demas
              gsap.to(imgToMove, {
                alpha: 0, rotate: 1, x: 20, duration: animDuration, onComplete: () => {

                  // borramos el slide del array 
                  images.splice(indexToMove, 1);

                  // movemos el slide para la primera posicion en el array usando unshift
                  images.unshift(imgToMove);

                  // despues simplemente asignamos z index dependiendo de la posicion del slide en el array.
                  images.forEach((img, f) => {
                    gsap.set(img, { zIndex: f })
                  })

                  gsap.to(imgToMove, { alpha: 1, rotate: 0, x: 0, duration: animDuration })

                }
              });

            } else {
              const imgToMove = images.splice(0, 1)[0];
              // como presionamos prev, sabemos q el slide q hay q mover es siempre el q esta detras (q en nuestro array es siempre el slide en el index 0)
              // aqui lo q hacemos es hacer push de ese slide a la ultima posicion usando push y splice

              gsap.to(imgToMove, {
                alpha: 0, rotate: -1, x: -20, duration: animDuration, onComplete: () => {
                  images.push(imgToMove);

                  images.forEach((img, f) => {
                    gsap.set(img, { zIndex: f })
                  })

                  gsap.to(imgToMove, { alpha: 1, rotate: 0, x: 0, duration: animDuration })
                }
              })

            }

          });
        });
      });
    }
  }
}
