export default function gsapAccordion(gsap) {
  const $accordion = document.querySelectorAll('[data-accordion]');
  if ($accordion.length) {
    $accordion.forEach((acc) => {
      const $accordionTrigger = acc.querySelectorAll('[data-accordion-trigger]');
      const windowWidth = window.innerWidth;
      const responsiveBreakpoint = acc.dataset.accordionResponsive
        ? acc.dataset.accordionResponsive
        : 0;
      const multipleOpened =
        typeof acc.dataset.accordionMultipleOpens != 'undefined' ? true : false;
      if (windowWidth <= responsiveBreakpoint || responsiveBreakpoint === 0) {
        const touchEvent = 'touchstart' in window ? 'touchstart' : 'click';
        if ($accordionTrigger.length) {
          $accordionTrigger.forEach((accTrigger) => {
            let parent = accTrigger.parentNode;
            let content = parent.querySelector('[data-accordion-content]');
            let isExpanded = accTrigger.dataset.accordionExpanded;
            // If any trigger has property "expanded"
            if (typeof isExpanded != 'undefined') {
              gsap.to(content, {
                height: 'auto',
                autoAlpha: 1,
                duration: 0.2,
              });
              content.setAttribute('aria-hidden', 'false');
              accTrigger.setAttribute('aria-expanded', 'true');
              parent.classList.add('expanded');
            }
            accTrigger.addEventListener(touchEvent, (e) => {
              e.preventDefault();
              e.stopPropagation();
              e.stopImmediatePropagation();
              if (parent.classList.contains('expanded')) {
                parent.classList.remove('expanded');
                gsap.to(content, {
                  height: 0,
                  autoAlpha: 0,
                  duration: 0.2,
                });
                content.setAttribute('aria-hidden', 'true');
                accTrigger.setAttribute('aria-expanded', 'false');
                accTrigger.classList.remove('active');
              } else {
                // Collapse open
                if (!multipleOpened) {
                  const expandedItem = acc.querySelector('.expanded');
                  if (expandedItem) {
                    let expandedContent = expandedItem.querySelector('.accordion-content');
                    let expandedTrigger = expandedItem.querySelector('button');
                    gsap.to(expandedContent, {
                      height: 0,
                      autoAlpha: 0,
                      duration: 0.2,
                    });
                    expandedContent.setAttribute('aria-hidden', 'true');
                    expandedTrigger.setAttribute('aria-expanded', 'false');
                    expandedTrigger.classList.remove('active');
                    expandedItem.classList.remove('expanded');
                  }
                }
                parent.classList.add('expanded');
                gsap.to(content, {
                  height: 'auto',
                  autoAlpha: 1,
                  duration: 0.2,
                });
                content.setAttribute('aria-hidden', 'false');
                accTrigger.setAttribute('aria-expanded', 'true');
                accTrigger.classList.add('active');
                // If not undefined, it means that there are some images to be changed.
                if (typeof accTrigger.dataset.target !== 'undefined') {
                  let target = acc.querySelector('.' + accTrigger.dataset.target);
                  const $images = acc.querySelectorAll('[data-link-image]');
                  if (target && $images.length) {
                    removeClass($accordionTrigger, 'active');
                    removeClass($images, 'active');
                    accTrigger.classList.add('active');
                    target.classList.add('active');
                  }
                }
              }
            });
          });
        }
      }
    });
  }
}

const removeClass = (elements, className) => {
  elements.forEach((element) => {
    element.classList.remove(className);
  });
};
