// overflow-block-full-image

import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const fullImgCard = () => {
  const fullImgDom = gsap.utils.toArray('section.overflow-block-full-image');

  if (fullImgDom.length > 0) {
    fullImgDom.forEach((el) => {
      const card = el.querySelector('.white-block ');

      if (card) {
        gsap.fromTo(
          card,
          { yPercent: 10 },
          {
            yPercent: -10,
            scrollTrigger: {
              trigger: card,
              start: 'top bottom',
              end: '100% top',
              scrub: true,
              // markers: true,
            },
          }
        );
      }
    });
  }
};

export default fullImgCard;
