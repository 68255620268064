import 'focus-visible';
import './fonts';
import { gsap } from 'gsap';
import { ScrollSmoother } from 'gsap/ScrollSmoother';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { Ajaxinate } from 'ajaxinate';
import ScrollTrigger from 'gsap/ScrollTrigger';
import animations from './animations';
import Nav from './navigation';
import layerSlider from './layer-slider';
import customCursor from './cursor';
import updatesSlider from './updates-slider';
import fullCarousel from './full-carousel';
import cursorImage from './cursor-image';
import tabsLocations from './tabs-locations';
import equalizerMultiple from './equalizer-multiple';
import chart from './chart';
import './utils/sticky-header';
import gsapAccordion from './accordions';
// import tabsBlock from './tabs-block';
import scrollerBlock from './scroller-block';
import './filters-v2';
import cookieConsent from './cookieconsent';
import loadMoreAjaxinate from './load-more-ajaxinate';
import loadMore from './load-more';
import asciiArt from './ascii-art';
// import announcementBanner from './announcement';
import announcements from './announcements';
import pageTransitions from './animations/page-transitions';
import './modal-video';

window.addEventListener("pageshow", (event) => {
  if (event.persisted) {
    // console.log('true')
      window.location.reload() 
  }
});

gsap.registerPlugin(ScrollSmoother, ScrollTrigger, ScrollToPlugin);

// eslint-disable-next-line
let smoother = ScrollSmoother.create({
  smooth: 1, // how long (in seconds) it takes to "catch up" to the native scroll position
  effects: true, // looks for data-speed and data-lag attributes on elements
  smoothTouch: 0.1, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
});

const mainNav = new Nav(document.querySelector('#main-header'));

pageTransitions(mainNav);
animations();
layerSlider();
customCursor();
updatesSlider();
fullCarousel();
cursorImage();
tabsLocations();
// tabsBlock();
scrollerBlock();
equalizerMultiple();
chart();
gsapAccordion(gsap);
// filters();
cookieConsent();
announcements();
loadMoreAjaxinate(Ajaxinate);
asciiArt();
loadMore();

const videosCustom = document.querySelectorAll('.full-video');

function playVid(el) {
  el.play();
}

function pauseVid(el) {
  el.pause();
}

if (videosCustom.length > 0) {
  videosCustom.forEach((videoCont) => {
    const cursorMob = videoCont.querySelector('.custom-pointer.mobile');
    const video = videoCont.querySelector('.main-video');

    if(videoCont.querySelector('.video-thumbmail')){
      const videoThumnail = videoCont.querySelector('.video-thumbmail');
      videoThumnail.addEventListener('click', (e) => {
        e.stopPropagation();
        videoThumnail.classList.add('hide')
        video.classList.toggle('playing');
        cursorMob.classList.toggle('playing');
        playVid(video);
        video.classList.remove('hide');
      });
    }


    video.addEventListener('click', () => {
      cursorMob.classList.toggle('playing');
      video.classList.toggle('playing');
      if (video.classList.contains('playing')) {
        playVid(video);
      } else {
        pauseVid(video);
      }
    });
  });
}

const mainHero = document.querySelector('.main-hero');
const mainNavDom = document.getElementById('main-header');

if (mainHero) {
  gsap.to(mainNavDom, {
    scrollTrigger: {
      trigger: mainHero,
      start: 'bottom top',
      onLeave: () => {
        mainNavDom.classList.remove('colored');
      },
      onEnterBack: () => {
        mainNavDom.classList.add('colored');
      },
      // markers: true,
    },
  });
}

window.addEventListener('resize', () => {
  equalizerMultiple();
});

// achors
const linkJumps = document.querySelectorAll('.link-jump');
if (linkJumps.length > 0) {
  linkJumps.forEach((link) => {
    const hash = link.getAttribute('href');
    link.addEventListener('click', (e) => {
      console.log('jumping')
      e.preventDefault();
      gsap.to(window, {
        delay: 0,
        duration: 0.7,
        ease: 'power2.in',
        scrollTo: { y: hash, offsetY: 0 },
      });
    });
  });
}

const contactLocations = document.querySelector('section.into-table');
if(contactLocations) {
  contactLocations.innerHTML = contactLocations.innerHTML.replace(/\u2028/g, ' ');
}

const ModalVideo = document.querySelector('modal-video');
document.querySelectorAll('[data-video-button]').forEach((button) => {
  button.addEventListener('click', (event) => {
    event.stopImmediatePropagation();
    if (button.getAttribute('aria-expanded') === 'true') return;
    ModalVideo.open(button);
  });
});
