import Splide from '@splidejs/splide';
// import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

export default function updatesSlider() {
  const draggerDom = document.querySelector('#dragger-js');

  if (draggerDom) {
    const slider = new Splide('#dragger-js', {
      type: 'slide',
      arrows: false,
      autoplay: false,
      //   focus: 'center',
      pagination: false,
      mediaQuery: 'min',
      drag: 'free',
      //   breakpoints: {
      //     768: {
      //       perPage: 2,
      //       gap: '2rem',
      //       padding: '0',
      //       focus: 1,
      //     },
      //   },
    });

    // const bar = draggerDom.querySelector('.my-slider-progress-bar');

    // Updates the bar width whenever the carousel moves:
    // slider.on('mounted dragging', () => {
    //     const end = slider.Components.Controller.getEnd() + 1;
    //     const rate = Math.min((slider.index + 1) / end, 1);
    //     bar.style.width = `${100 * rate}%`;
    // });

    document.addEventListener('DOMContentLoaded', () => {
      slider.mount();
    });
  }
}
