import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(ScrollTrigger, SplitText);

const homepageAnim = () => {
  const screenHeight = window.innerHeight;
  const block = document.querySelector('.geometric-block-with-paragraph-and-text')
  if (block) {


    // HERO
    const textToSplitHero = block.querySelectorAll('.splitJS-hero');
    const heroP = block.querySelectorAll('.geometric-block-with-paragraph-and-text p');
    const heroImgs = block.querySelectorAll(
      '.geometric-block-with-paragraph-and-text .picture-image'
    );

    let splitDivs;
    let splitLines;

    document.fonts.ready.then(() => {
      splitDivs = new SplitText(textToSplitHero, { type: 'lines', linesClass: 'pos-line' });
      splitLines = new SplitText(splitDivs.lines, { type: 'lines', linesClass: 'anim-line' });

      gsap.to(splitLines.lines, {
        y: 0,
        duration: 1,
        ease: 'power4',
        stagger: 0.1,
        delay: 0,
        alpha: 1,
        onStart: () => {
          gsap.set('.splitJS-hero', { alpha: 1 });
        },
        onComplete: () => {
          gsap.set('.splitJS-hero .pos-line', { overflow: 'visible' });
        },
      });

      gsap.to(heroP, { alpha: 1, y: 0, delay: 0.3, duration: 1 });

      gsap.to(heroImgs, { alpha: 1, stagger: 0.2, delay: 0.4, duration: 0.5 });

      gsap.to(heroImgs, {
        y: -40,
        stagger: 0.05,
        // ease: 'power2.in',
        scrollTrigger: {
          trigger: 'body',
          start: 'top top',
          invalidateOnRefresh: true,
          end: `${screenHeight} top`,
          scrub: 2,
        },
      });

      const mm = gsap.matchMedia();

      mm.add("(max-width: 1280px)", () => {
        const mobileImgTl = gsap.timeline({
          repeat: -1,
        });

        const insertTime = '<.5'

        mobileImgTl.to('.geometric-block-with-paragraph-and-text .picture-image.is-circle img.one', {
          alpha: 0,
          duration: 3,
          delay: 2,
        })
        mobileImgTl.to('.geometric-block-with-paragraph-and-text .picture-image.is-circle img.two', {
          alpha: 1,
          duration: 3
        }, insertTime)
        mobileImgTl.to('.geometric-block-with-paragraph-and-text .picture-image.is-circle img.two', {
          alpha: 0,
          duration: 3,
          delay: 2,
        })
        mobileImgTl.to('.geometric-block-with-paragraph-and-text .picture-image.is-circle img.three', {
          alpha: 1,
          duration: 3
        }, insertTime)
        mobileImgTl.to('.geometric-block-with-paragraph-and-text .picture-image.is-circle img.three', {
          alpha: 0,
          duration: 3,
          delay: 2,
        })
        mobileImgTl.to('.geometric-block-with-paragraph-and-text .picture-image.is-circle img.one', {
          alpha: 1,
          duration: 3
        }, insertTime)
      });

    });

    window.addEventListener('resize', () => {
      splitDivs.revert();
      splitLines.revert();
    });
  }
};

export default homepageAnim;
