/* eslint-disable */
import Chart from 'chart.js/auto';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';


gsap.registerPlugin(ScrollTrigger);

Chart.defaults.font.family = 'Ovo';
if (getDevice() == 'desktop') {
  Chart.defaults.font.size = 23;
} else {
  Chart.defaults.font.size = 18;
}

export default function chart() {
  const chartContainers = document.querySelectorAll('.chart-block-graphics');

  if (chartContainers.length) {
    chartContainers.forEach((chartContainer) => {
      const chartType = chartContainer.dataset.chartType || 'pie';
      // const chartLabelPrefix = chartContainer.dataset.chartLabelPrefix || '';
      const ctx = chartContainer.querySelector('canvas').getContext('2d');
      const segments = chartContainer.querySelectorAll('.chart-block-segment');

      if (segments.length) {
        const data = getPieChartData(segments);

        const options = {
          type: chartType,
          data: {
            labels: data.labels,
            datasets: [
              {
                fill: chartType === 'pie',
                backgroundColor: data.colors,
                borderColor: chartType === 'line' ? data.colors : 'white',
                data: data.values,
                borderWidth: chartType === 'pie' ? 0 : 2,
              },
            ],
          },

          options: {
            responsive: true,
            // Maintain the original canvas aspect ratio (width / height) when resizing.
            maintainAspectRatio: true,
            scales:
              chartType === 'line'
                ? {
                  y: {
                    ticks: {
                      display: false,
                    },
                    max: (scale) => {
                      const max = scale.chart.data.datasets[0].data.reduce((p, v) => {
                        return p > v ? p : v;
                      });
                      if (getDevice() == 'desktop') {
                        return max * 1.005;
                      } else if (getDevice() == 'tablet') {
                        return max * 1.01;
                      } else {
                        return max * 1.017;
                      }
                    },
                    min: (scale) => {
                      const min = scale.chart.data.datasets[0].data.reduce((p, v) => {
                        return p < v ? p : v;
                      });
                      if (getDevice() == 'desktop') {
                        return min * 0.97;
                      } else if (getDevice() == 'tablet') {
                        return min * 0.95;
                      } else {
                        return min * 0.9;
                      }
                    },
                    // stacked: true,
                    grid: {
                      drawTicks: false,
                      color: '#f8f6f2',
                    },
                  },
                  x: {
                    ticks: {
                      color: '#0f2e58',
                    },
                    grid: {
                      color: '#0f2e58',
                    },
                  },
                }
                : {},
            plugins: {
              tooltip: {
                enabled: false, //hover 
                displayColors: false,
              },
              legend: {
                display: false,
              },
            },
          },
        }

        let chartInstance;

        if(chartType === 'pie'){
          ScrollTrigger.create({
            trigger: chartContainer,
            start: 'top 50%',
            end: '50px 50%',
            once: true,
            onEnter: () => {
              chartInstance = new Chart(ctx, options);
              // chartInstance.data
              // chartInstance.update();
            },
            // markers: true
          })
        }else{
          chartInstance = new Chart(ctx, options);
        }

      }
    });
  }
}

function getPieChartData(segments) {
  let data = { labels: [], values: [], colors: [] };
  segments.forEach((segment) => {
    data.labels.push(segment.dataset.label);
    data.values.push(segment.dataset.value);
    data.colors.push(segment.dataset.color);
  });
  return data;
}

/**
 * Get Device Type function
 * @returns
 */
function getDevice() {
  const ua = navigator.userAgent;
  if (
    /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua) ||
    (window.matchMedia('only screen and (min-width: 768px)').matches &&
      window.matchMedia('only screen and (max-width: 1199px)').matches)
  ) {
    return 'tablet';
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    ) ||
    window.matchMedia('only screen and (max-width: 767px)').matches
  ) {
    return 'mobile';
  }
  return 'desktop';
}
