export default function loadMore() {
    const cardsGrid = document.querySelectorAll('section.cards-grid.donors-grid');
    if (cardsGrid.length) {
      cardsGrid.forEach(section => {
        if(section.querySelectorAll('.cards-grid-btn').length){
            const btnContainer = section.querySelector('.cards-grid-btn');
            const btn = section.querySelector('.cards-grid-btn button');
            btn.addEventListener('click', ()=>{
                const cards = section.querySelectorAll('.simple-card');
                cards.forEach(card => {
                    card.classList.remove('hidden');
                })
                btnContainer.style.display = 'none';
            })

        }
      })
    }
  }
  