import { gsap } from 'gsap';

export default class Nav {
	constructor(el) {
		this.documentBody = document.querySelector('body');
		this.mainNav = el;
		this.toggleNav = this.mainNav.querySelector('.toggle-nav');
		this.openNavContent = this.mainNav.querySelector('.open-nav-content');
		this.navLinks = this.mainNav.querySelectorAll('.primary-navigation li');
		this.childrenNav = this.mainNav.querySelectorAll('li.has-child');
		this.navLine = this.mainNav.querySelectorAll('.primary-navigation .line');
		this.secondaryNav = this.mainNav.querySelectorAll('.secondary-navigation');
		this.hiddenSubMenuLinks = this.mainNav.querySelectorAll('.open-nav a');
		this.headerLinks = this.mainNav.querySelectorAll('.main-nav a');
		this.focusTrap = this.mainNav.querySelector('[data-focus-trap]');
		this.focusTrapFirst = this.mainNav.querySelector('[data-focus-trap-first]');
		this.focusInternalAnchors = this.mainNav.querySelectorAll('.secondary-links a');

		this.focusLastChildAnchor = null;
		this.focusFirstChildAnchor = null;

		// Focus trap elements - accessibility
		if (this.focusInternalAnchors.length) {
			this.focusLastChildAnchor = [...this.focusInternalAnchors].pop();
			this.focusFirstChildAnchor = [...this.hiddenSubMenuLinks].shift();
			if (this.focusLastChildAnchor) {
				this.focusLastChildAnchor.addEventListener('focus', () => {
					this.focusTrap.setAttribute('tabindex', 0);
				});
			}
			if (this.focusFirstChildAnchor) {
				this.focusFirstChildAnchor.addEventListener('focus', () => {
					this.focusTrapFirst.setAttribute('tabindex', 0);
				});
			}
		}

		gsap.set(this.navLinks, { alpha: 0, x: -100 });
		gsap.set(this.secondaryNav, { alpha: 0 });

		this.navTl = gsap.timeline({ paused: true });

		this.navTl.to(this.openNavContent, {
			y: 0,
			duration: 0.6,
			ease: 'power2.inOut'
		})
		.to(this.navLinks, {
			alpha: 1,
			x:  0,
			stagger: 0.05
		}, 0.3)
		.to(this.navLine, {scaleY: '100%'}, 0.5)
		.to(this.secondaryNav, {alpha: 1, ease: 'power2.inOut'}, 0.8);

		// Toggle Navigation
		this.toggleNav.addEventListener('click', () => {
			if (!this.documentBody.classList.contains('nav-opened')) {
				this.documentBody.classList.add('nav-opened');
				this.navTl.play();
				// Accessibility attributes
				this.toggleNav.setAttribute('aria-expanded', true);
				this.toggleNav.setAttribute('aria-hidden', false);
				this.focusTrap.setAttribute('tabindex', 0);
				this.toggleTabIndex(this.hiddenSubMenuLinks, true);
				this.toggleTabIndex(this.headerLinks, false);
				// Burger icon open
				this.toggleNav.classList.add('is-active');
			} else {
				this.navTl.reverse();
				this.documentBody.classList.remove('nav-opened');
				// Accessibility attributes
				this.toggleNav.setAttribute('aria-expanded', false);
				this.toggleNav.setAttribute('aria-hidden', true);
				this.focusTrap.setAttribute('tabindex', -1);
				this.toggleTabIndex(this.hiddenSubMenuLinks, false);
				this.toggleTabIndex(this.headerLinks, true);
				// Burger icon closed
				this.toggleNav.classList.remove('is-active');
			}
		});

		// Focus trap events - accessibility
		this.focusTrap.addEventListener('focus', () => {
			this.toggleNav.focus();
			this.focusTrap.setAttribute('tabindex', -1);
		});
		this.focusTrapFirst.addEventListener('focus', () => {
			this.focusLastChildAnchor.focus();
			this.focusTrapFirst.setAttribute('tabindex', -1);
		});

		this.childrenNav.forEach(li => {
			const btn = li.querySelector('span');
			const accordion = btn.nextElementSibling;
			const accTl = gsap.timeline({paused: true});
			accTl.to(accordion, {height: 'auto'});

			if(accordion.classList.contains('open')){
				accTl.progress(1, false);
			}

			btn.addEventListener('click', ()=>{
				btn.classList.toggle('open');
				accordion.classList.toggle('open');

				if(accordion.classList.contains('open')){
					accTl.play()
				}else{
					accTl.reverse()
				}
			})
		})
	}

	closeNavAnim(color, link){
		const clickedLinkTl = gsap.timeline({onComplete: ()=>{
			window.location.href = link
		}});

		clickedLinkTl.to(this.secondaryNav, {alpha: 0, ease: 'power2.inOut'})
		.to(this.navLine, {height: '0'})
		.to(this.navLinks, {
			alpha: 0,
			x:  -50,
			stagger: 0.05
		}, 0.3)
		.to(this.toggleNav, {
			alpha: 0
		}, 0.3)
		.to(this.openNavContent, {
			backgroundColor: color,
			duration: 0.5,
			ease: 'power2.inOut'
		}, 0.8)
	}

	showNav(){
		gsap.to(this.mainNav, {y: 0, ease: 'power2.out', delay: 0.6});
	}

	toggleTabIndex(links, focusable) {
		links.forEach((link) => {
			if (focusable ? link.removeAttribute('tabIndex') : link.setAttribute('tabindex', -1));
		});
	}
}
