import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const statsAnim = () => {
  const statsDom = document.querySelectorAll('.numeralia-container');

  statsDom.forEach((el) => {
    if (statsDom.length > 0) {
      // learn how this code works at https://www.creativeCodingClub.com
      const screen = el.querySelector('.screen');
      const textContainer = el.querySelector('.numeralia-text-content');
      const elements = document.querySelectorAll(".numeralia-item")

      gsap.to(screen, {
        x: 0,
        ease: 'power2.out',
        scrollTrigger: {
          trigger: el,
          start: 'top 80%',
          // markers: true,
        },
      });

      gsap.from(textContainer, {
        alpha: 0,
        delay: 0.3,
        scrollTrigger: {
          trigger: el,
          start: 'top 80%',
        },
      });


      gsap.registerEffect({
        name: "counter",
        extendTimeline: true,
        defaults: {
          end: 0,
          duration: 1.5,
          ease: "power1",
          increment: 1,
        },
        effect: (targets, config) => {
          const tl = gsap.timeline()
          const num = targets[0].innerText.replace(/\,/g, '')
          // eslint-disable-next-line no-param-reassign
          config.end = config.end.replace(/\,/g, '');

          // eslint-disable-next-line no-param-reassign
          targets[0].innerText = num

          tl.to(targets, {
            duration: config.duration,
            innerText: config.end,
            // snap:{innerText:config.increment},
            modifiers: {
              // eslint-disable-next-line func-names, object-shorthand
              innerText: function (innerText) {
                return gsap.utils.snap(config.increment, innerText).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

              }
            },
            ease: config.ease
          }, 0)

          return tl
        }
      })

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: el,
          start: 'top 50%',
          // markers: true
        }
      })

      elements.forEach(el => {
        const stat = el.querySelector('.to-animate')
        const end = stat.getAttribute('data-ending');
        const desc = el.querySelector('.stat-desc');

        tl.from(el, { opacity: 0 }, "-=0.5")
        tl.from(desc, { opacity: 0 }, '-=0.5')
        tl.counter(stat, { end, ease: "linear" }, "-=0.6")
      })
    }
  })
};

export default statsAnim;
