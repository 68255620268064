import Splide from '@splidejs/splide';

export default function fullCarousel() {
  if (document.querySelector('.full-width-carousel-container')) {
    const pageWrappers = document.querySelectorAll(
      '.full-width-carousel-pagination-card .active-item'
    );
    const slider = new Splide('.full-width-carousel-container', {
      type: 'loop',
      arrows: true,
      autoplay: false,
      pagination: false,
      mediaQuery: 'min',
      drag: 'free',
    });
    document.addEventListener('DOMContentLoaded', () => {
      slider.mount();
      slider.on('move', (newIndex) => {
        pageWrappers.forEach((pageCounter) => {
          // eslint-disable-next-line no-param-reassign
          pageCounter.innerHTML = newIndex + 1;
        });
      });
    });
  }
}
