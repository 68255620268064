import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const centeredPromoAnim = () => {
  const centeredPromo = document.querySelectorAll('.centered-promo-text');
  if (centeredPromo) {
    centeredPromo.forEach((el) => {
      const imgs = el.querySelectorAll('.picture-image');
      const header = el.querySelectorAll('h3');

      const imgOne = el.querySelector('.picture-image.is-circle img.one')
      const imgTwo = el.querySelector('.picture-image.is-circle img.two')
      const imgThree = el.querySelector('.picture-image.is-circle img.three')

      gsap.from(header, {
        alpha: 0,
        y: 20,
        duration: 1,
        ease: 'power4',
        scrollTrigger: {
          trigger: header,
          start: 'top bottom',
          // markers: true,
        },
      });

      gsap.to(imgs, {
        y: -40,
        stagger: 0.3,
        // ease: 'power2.in',
        scrollTrigger: {
          trigger: el,
          start: 'top 100%',
          end: `100% top`,
          scrub: 2,
          //
        },
      });

      const mm = gsap.matchMedia();

      mm.add("(max-width: 768px)", () => {

        const mobileImgTl = gsap.timeline({
          repeat: -1,
        });

        const insertTime = '<.5'

        mobileImgTl.to(imgOne, {
          alpha: 0,
          duration: 3,
          delay: 2,
        })
        mobileImgTl.to(imgTwo, {
          alpha: 1,
          duration: 3
        }, insertTime)
        mobileImgTl.to(imgTwo, {
          alpha: 0,
          duration: 3,
          delay: 2,
        })
        mobileImgTl.to(imgThree, {
          alpha: 1,
          duration: 3
        }, insertTime)
        mobileImgTl.to(imgThree, {
          alpha: 0,
          duration: 3,
          delay: 2,
        })
        mobileImgTl.to(imgOne, {
          alpha: 1,
          duration: 3
        }, insertTime)
      });

    });
  }
};

export default centeredPromoAnim;
