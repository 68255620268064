import { gsap } from 'gsap';
import mainHeroAnim from './main-hero';
import homepageAnim from './homepage';

export default function pageTransitions(nav) {

    const links = document.querySelectorAll('a:not(.main-nav-js-link):not(.link-jump):not(.skip-content):not(.no-courtain)');
    const navLinks = document.querySelectorAll('.main-nav-js-link');
    const screen = document.getElementById('transition-screen');
    const openingTl = gsap.timeline({ paused: true });
    openingTl.to(screen, { yPercent: 100, duration: 0.5, ease: 'power2.in' });

    if (document.querySelector('.main-hero')) {
        openingTl.call(mainHeroAnim, null, 0.4)
    } else if (document.querySelector('.geometric-block-with-paragraph-and-text')) {
        openingTl.call(homepageAnim, null, 0.4)
    }

    window.addEventListener("load", () => {

        openingTl.play();
        // nav.showNav();
    });

    const prepSrcreen = (globalScreen, targetLink) => {
        let dest = targetLink.getAttribute('data-dest');

        if (dest == null || dest === '' || dest === undefined) {
            dest = 'apply-residents';
        }

        // eslint-disable-next-line no-param-reassign
        globalScreen.className = '';
        globalScreen.classList.add(dest);
    }

    links.forEach((item) => {

        item.addEventListener('click', (e) => {
            console.log('cliking')
            const same = sameSite(e.currentTarget.getAttribute('href'));

            if(same){
                e.preventDefault();
                const url = e.currentTarget.getAttribute('href');
                prepSrcreen(screen, e.currentTarget);
    
                gsap.to(screen, {
                    yPercent: 0, duration: 0.5, ease: 'power1.out', onComplete: () => {
                        window.location.href = url;
                    }
                });
            }
    
        })
    })

    navLinks.forEach(item => {

        item.addEventListener('click', (e) => {
            e.preventDefault();
            prepSrcreen(screen, e.currentTarget);

            const color = getComputedStyle(screen).getPropertyValue('background-color');

            nav.closeNavAnim(color, e.currentTarget.getAttribute('href'));

        })
    })

    function sameSite(uri) {
        const matches = uri.match(/^(https?:)?\/\/([^\/]+)/);
        if (!matches) {
          // If URI is not absolute or protocol-relative then it is always same-origin
          return true;
        }
        return window.location.host === matches[2];
    };

}