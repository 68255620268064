import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const imgTextAnim = () => {
  const imgWithText = document.querySelectorAll('.image-with-text');
  if (imgWithText) {
    imgWithText.forEach((el) => {
      const img = el.querySelector('img');
      const textContainer = el.querySelector('.iwt-text-wrapper');
      // eslint-disable-next-line

      const toggleActions = 'play none none none';

      gsap.fromTo(
        img,
        { yPercent: 6 },
        {
          yPercent: -4,
          scrollTrigger: {
            trigger: img,
            start: 'top bottom',
            invalidateOnRefresh: true,
            end: '100% top',
            scrub: true,
            // markers: true
          },
        }
      );

      gsap.from(textContainer, {
        alpha: 0,
        y: 20,
        delay: 0.2,
        scrollTrigger: {
          trigger: el,
          start: 'top 50%',
          toggleActions,
          // markers: true
        },
      });

    });
  }
};

export default imgTextAnim;
