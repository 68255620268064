import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const layerSliderAnim = () => {
  const layerSlider = document.querySelectorAll('section.layer-slider');

  if (layerSlider.length) {
    layerSlider.forEach((el) => {
      // const imgsContainer = el.querySelector('.images-gallery');
      const imgs = el.querySelectorAll('img');

      const imgsReversed = gsap.utils.toArray(imgs);

      gsap.to(imgsReversed, {
        alpha: 1,
        duration: 1,
        ease: 'power4',
        scrollTrigger: {
          trigger: el,
          start: 'top 80%',
        },
      });
    });
  }
};

export default layerSliderAnim;
