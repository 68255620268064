import { gsap } from 'gsap';

export default function cursorImage() {
  const cursorPanel = document.querySelectorAll('.cursor-image-panel');
  if (cursorPanel) {
    // eslint-disable-next-line prefer-arrow-callback
    cursorPanel.forEach((itemPanel) => {
      const image = itemPanel.querySelector('.cursor-image');
      const imageWidth = image.offsetWidth; /* 1.5 - 30;*/
      const imageHeight = image.offsetHeight;

      const moveCursor = (e) => {
        let rect = e.currentTarget.getBoundingClientRect();

        // const x = e.clientX - rect.left - imageWidth;
        const x = e.offsetX - image.offsetWidth;
        const y = e.clientY - rect.top;

        gsap.set(image, { x, y });

        // image.style.transform = `translate3d(${cursorX}px, ${cursorY}px, 0)`;
      };

      itemPanel.addEventListener('mousemove', moveCursor);
    });
  }
}
