export default function loadMoreAjaxinate(Ajaxinate) {
  const ajaxinateWrappers = document.querySelectorAll('section.cards-grid .cards-grid-wrapper');
  if (ajaxinateWrappers.length) {
    for (let i = 0; i < ajaxinateWrappers.length; i++) {
      new Ajaxinate({
        container: `#cards-grid-container-${i}`,
        pagination: `#cards-grid-btn-${i}`,
        method: 'click',
      });
    }
  }
}
